import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Divider from '../components/Divider';
import CTA from '../components/CTA';
import Tagline from '../components/Tagline';

function ApproachPage() {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "process.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      renderHero={() => {
        return (
          <Hero
            fluidImage={data.heroImage.childImageSharp.fluid}
            title="My Approach"
          />
        );
      }}
    >
      <SEO title="My Approach" />

      <Tagline>
        I provide a multilayered approach that supports Colorado organizations
        to transform their cultures and practices to be more equitable spaces
        that produce belonging.
      </Tagline>

      <Divider />

      <h2>1.Personalizing the Work</h2>
      <p>
        It would be impossible to know every industry and your personal work
        culture, and I don’t claim to be able to do this. But, having worked
        with organizations in Education, State Government, Startups,
        Non-profits, and Criminal Justice, I have found patterns in the types
        of conversations that need to happen.
      </p>
      <p>
        All workshops, consulting, and coaching will be personalized to your
        organization, and driven by some key building blocks for success.
        These building blocks were forged through my doctoral level expertise
        and real world experience leading EDI in organizations. The work we
        do together will be personalized to your organization and employees,
        will be informed by current theories and research, be results driven,
        and provide an evaluation component. I believe strongly in the need
        for this level of rigor to be present, because my number one goal is
        to create transformation.
      </p>
      <p>
        Our work together will be focused on transformation- building a lens
        together so that natural actions will achieve desired results. This
        is a joint endeavor, driven by your needs and goals and supported by
        my expertise. Together, we will shift the conversation from one of
        compliance and shame to one of inclusion and analyzing how your
        organization can BE BRAVE in these difficult conversations.
      </p>

      <Divider />

      <h2>2. Creating a Brave Space to Make EDI Approachable</h2>
      <p>
        Unfortunately, I hear this far too often. Given the personal and
        important nature of this work, professionals take different
        approaches. Not all approaches work for all people, and some can
        leave people at an earlier point in their journey feeling shamed and
        demonized.
      </p>
      <p>
        Growing up in a white community with white parents and teaching at
        predominantly white universities has helped me to develop a more
        gentle approach to guiding people in their journeys. My approach
        creates a space for people to authentically enter these often taboo
        conversations, at any place in their journey. I center the work on
        assuming positive intent, but making sure that the group also
        grapples with the impact of unintentionally hurtful statements, using
        these as learning opportunities.
      </p>
      <p>
        Through our work, I will guide individuals and organizations through
        headwork and heartwork, creating a cadence that grows your ability to
        move between both forms of work.
      </p>
      <p>
        Headwork is the research-based theories and methods we guide you
        through. These have interdisciplinary roots in sociology, psychology,
        neurology, anthropology, history, and women’s studies.
      </p>
      <p>
        Heartwork is, for many, the hardwork. I guide you through the
        emotions and vulnerability that comes with building your own
        awareness and being authentic. It is this heartwork that is the key
        to being brave and true transformation.
      </p>

      <Divider />

      <h2>3. Centering EDI Work in Real World Benefits</h2>
      <p>
        EDI work is about more than simply teaching people and organizations
        to “be nice” or search out the “bad apples.” When organizations come
        into the work with the sole goal of ‘being better people”, EDI work
        is either or viewed as unnecessary or pushed to the backburner due to
        pressures the organization faces (limited resources, deadlines, time
        constraints, annual goals).
      </p>

      <p>
        My work with organizations is rooted in understanding the benefits
        (time, money, turnover, productivity, employee satisfaction) to their
        organizations. For organizations to truly transform, they must all
        see benefit in the work they are doing. The work that it takes to
        transform requires effort and resources, and isn’t a silver bullet
        that will magically make your organization run smoothly. But, with
        time, transformation, effort, and mindset shifts, your organization
        will gain the tools to being more effective and productive in the
        future.
      </p>

      <Divider />

      <h2>4. Grounding Services in Research and Evaluation</h2>
      <p>
        My doctoral studies were focused on educational access, looking at
        inequities at both identity formation (individual level) and system
        level inequities. This has given me a theoretical and research based
        foundation in which I do this work. In addition, I am dedicated to
        real change, and to do this requires evaluation. Without evaluation,
        this work can leave people feeling warm and fuzzy, but without the
        skills to know how to continue to grow and develop. I provide
        formative and summative evaluations, that are both qualitative and
        quantitative. These evaluations provide a more clear roadmap of root
        problems, as well as an opportunity for organizations to benchmark
        their successes.
      </p>

      <Divider />

      <CTA to="/about/">About Me</CTA>
    </Layout>
  );
}

export default ApproachPage;
