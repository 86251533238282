import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tagline.module.css';

function Tagline({ children }) {
  return <p className={styles.tagline}>{children}</p>;
}

Tagline.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tagline;
